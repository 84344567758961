import Image1 from "../../assets/testimonial1.png";
import Image2 from "../../assets/testimonial2.png";
import Image3 from "../../assets/testimonial3.png";

export const Data = [
  {
    id: 1,
    image: Image1,
    title: "Джон Доу",
    description:
      "Действительно хорошая работа, все аспекты проекта были выполнены шаг за шагом и дали хорошие результаты.",
  },
  {
    id: 2,
    image: Image2,
    title: "Гарри Клинтон",
    description:
      "Хочу выразить огромную благодарность за отличную работу! Каждый аспект проекта был выполнен шаг за шагом с невероятным вниманием к деталям, и результаты превзошли все ожидания.",
  },
  {
    id: 3,
    image: Image3,
    title: "Сара Силл",
    description:
      "Проект был выполнен великолепно! Каждый аспект был тщательно проработан и реализован шаг за шагом с отличными результатами. Работа выполнена профессионально, с учетом всех требований и пожеланий. ",
  },
];
